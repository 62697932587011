<style lang="less" scoped>
/deep/ .ant-form-item-label {
  text-align: left !important;
  width: 100px;
}
</style>
<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="8">
<!--          <a-col :md="4" :sm="16">-->
<!--            <a-form-item label="岗位编号">-->
<!--              <a-input v-model="queryParam.code" placeholder="" />-->
<!--            </a-form-item>-->
<!--          </a-col>-->
<!--          <a-col :md="4" :sm="24">-->
<!--            <a-form-item label="岗位名称">-->
<!--              <a-input v-model="queryParam.name" placeholder="" />-->
<!--            </a-form-item>-->
<!--          </a-col>-->
<!--          <a-col :md="4" :sm="24">-->
<!--            <a-form-item label="组织名称">-->
<!--              <a-input v-model="queryParam.orgName" placeholder="" />-->
<!--            </a-form-item>-->
<!--          </a-col>-->
<!--          <a-col :md="4" :sm="24">-->
<!--            <a-form-item label="使用状态">-->
<!--              <a-select v-model="queryParam.status" placeholder="请选择" default-value="0">-->
<!--                <a-select-option value="0">全部</a-select-option>-->
<!--                <a-select-option value="1">正常</a-select-option>-->
<!--                <a-select-option value="2">禁用</a-select-option>-->
<!--              </a-select>-->
<!--            </a-form-item>-->
<!--          </a-col>-->

          <a-col :md="5" :sm="24">
            <a-form-item label="">
              <a-select v-model="queryParam.queryType" placeholder="请选择" default-value="0">
                <a-select-option value="1">创建人</a-select-option>
                <a-select-option value="2">更新人</a-select-option>
                <a-select-option value="3">名称</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="5" :sm="24">
            <a-form-item label="">
              <a-input v-model="queryParam.name" placeholder="" />
            </a-form-item>
          </a-col>

          <a-col :md="(!advanced && 8) || 24" :sm="24">
            <span
              class="table-page-search-submitButtons"
              :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
            >
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
              <a-button style="margin-left: 8px" @click="() => (queryParam = {})">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd()" v-action:but_role_add>新建</a-button>
    </div>

    <s-table
      ref="table"
      size="default"
      :rowKey="(record) => record.id"
      :columns="columns"
      :pagination="pagination"
      :data="loadData"
      :pageSize="50"
      :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      showPagination="auto"
      :scroll="{ x: 1000, y: scrollHeight }"
    >
      <!--      <span slot="serial" slot-scope="text, record, index">-->
      <!--        {{ index + 1 }}-->
      <!--      </span>-->
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleEdit(record)" v-if="iscandel(record)" v-action:but_role_edit>编辑&ensp;|&ensp;</a>
          <a @click="handleDelRole(record)" v-if="iscandel(record)" v-action:but_role_del>删除&ensp;|&ensp;</a>
          <a @click="handleBind(record)" v-if="iscandel(record)" v-action:but_role_bind>绑定权限</a>
          <!--如果需要权限 v-action:roleBD -->
        </template>
      </span>
    </s-table>

    <a-modal
      key="addrole"
      title="新增/编辑"
      :width="550"
      :visible="visible_addrole"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      :closable="true"
      @cancel="addRoleHandleCancel"
      :centered="true"
      :mask="true"
      :footer="null"
      :maskClosable="false"
      :body-style="{ height: 'auto' }"
    >
      <div>
        <a-form @submit="handleSubmit" :form="form">
          <a-form-item
            label="角色名称"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 17 }, sm: { span: 17 } }"
          >
            <a-input v-decorator="['name', { rules: [{ required: true, message: '请输入名称' }] }]" name="name" />
          </a-form-item>

          <a-form-item
            label="角色编号"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 17 }, sm: { span: 17 } }"
          >
            <a-input v-decorator="['code', { rules: [{ required: true, message: '请输入编号' }] }]" name="code" />
          </a-form-item>

          <a-form-item
            label="角色描述"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 17 }, sm: { span: 17 } }"
          >
          <a-textarea
            v-decorator="['remark', { rules: [{ required: false, message: '角色描述' }] }]"
            name="remark"
          ></a-textarea>
          </a-form-item>

          <a-form-item :wrapperCol="{ span: 24 }" class="form-submit">
            <a-button style="margin-right: 20px" @click="addRoleCancel">取消</a-button>
            <a-button htmlType="submit" type="primary">提交</a-button>
          </a-form-item>
        </a-form>
      </div>
    </a-modal>

    <!-- 绑定权限弹窗 -->

    <a-modal
      key="bindPermisstion"
      title="绑定权限"
      :width="500"
      :visible="visible_bindperm"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      :closable="true"
      @cancel="bindpermHandleCancel"
      :centered="true"
      :mask="true"
      :footer="null"
      :maskClosable="false"
      :body-style="{ height: '520px' }"
    >
      <div style="height: 420px; overflow-y: auto">
        <a-tree
          checkable
          :tree-data="treeData"
          v-model="expandedKeys"
          v-if="treeData.length"
          :default-expand-all="true"
          @check="treeSelect"
        >
        </a-tree>
      </div>
      <div style="text-align:right;marginTop:20px">
        <a-button style="margin-right: 18px" @click="bindpermHandleCancel">取消</a-button>
        <a-button @click="bindRolePermition" type="primary">提交</a-button>
      </div>
    </a-modal>
  </a-card>
</template>

          <script>
import moment from 'moment'
import { STable } from '@/components'
import STree from '@/components/Tree/Tree'
import { Tree } from 'ant-design-vue'
import {
  findPageRole,
  getServiceList,
  saveRole,
  deleteRole,
  updateRole,
  getOrgTree,
  getOrgPermTree,
  getRolePermTree,
  bindRolePermition,
} from '@/api/manage'

export default {
  name: 'TableList',
  components: {
    STable,
    STree,
    Tree,
  },
  data() {
    return {
      mdl: {},
      openKeys: ['1'],
      // 高级搜索 展开/关闭
      advanced: false,
      pagination: { pageSizeOptions: ['50', '100', '200', '500'] },
      treeData: [],
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '名称',
          width: '150px',
          key: 'name',
          dataIndex: 'name',
          align: 'center',
        },
        {
          title: '编号',
          width: '150px',
          key: 'code',
          dataIndex: 'code',
          align: 'center',
        },
        {
          title: '组织',
          width: '150px',
          key: 'orgName',
          dataIndex: 'orgName',
          align: 'center',
        },
        {
          title: '创建人',
          width: '150px',
          key: 'creatorName',
          dataIndex: 'creatorName',
          align: 'center',
        },
        {
          title: '更新人',
          width: '150px',
          key: 'editorName',
          dataIndex: 'editorName',
          align: 'center',
        },
        {
          title: '状态',
          width: '150px',
          key: 'status',
          dataIndex: 'status',
          align: 'center',
          customRender: (text) => {
            let ret = ''
            if (text == 1) {
              ret = '正常'
            } else {
              ret = '禁用'
            }
            return ret
          },
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          width: '180px',
          scopedSlots: { customRender: 'action' },
        },
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: (parameter) => {
        console.log('loadData.parameter', parameter)
        let param = {}
        param = Object.assign(parameter, this.queryParam)
        return findPageRole(param).then((res) => {
          return res.result
        })
      },
      selectedRowKeys: [],
      selectedRows: [],
      options: {
        alert: {
          show: true,
          clear: () => {
            this.selectedRowKeys = []
          },
        },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange,
        },
      },
      optionAlertShow: false,
      confirmLoading: false,
      visible_addrole: false,
      form: this.$form.createForm(this),
      tabObj: {}, //当前对象，编辑是用到
      addFlag: true, //true新增false编辑
      visible_bindperm: false,
      expandedKeys: [], //已绑定的keys
      submitKeys: [], //提交保存的key,包括半选中和选中
      thisRoleId: null, //当前选择的角色id
      scrollHeight: 0,
    }
  },
  created() {
    this.tableOption()
    this.scrollHeight = document.body.scrollHeight - 400
  },
  filters: {},
  methods: {
    tableOption() {
      if (!this.optionAlertShow) {
        this.options = {
          alert: {
            show: true,
            clear: () => {
              this.selectedRowKeys = []
            },
          },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange,
          },
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null,
        }
        this.optionAlertShow = false
      }
    },
    handleEdit(record) {
      this.visible_addrole = true
      this.tabObj = record
      this.addFlag = false
      this.$nextTick(() => {
        this.form.setFieldsValue({
          name: record.name,
          code: record.code,
          remark: record.remark,
        })
      })
    },
    handleDelRole(record) {
      deleteRole(record)
        .then((res) => {
          //提交成功
          this.$message.success('删除成功')
          this.$refs.table.refresh()
        })
        .catch((e) => {
          this.$message.error('删除失败')
        })
    },
    handleOk() {},

    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    resetSearchForm() {
      this.queryParam = {
        date: moment(new Date()),
      }
    },
    addRoleHandleCancel() {
      this.visible_addrole = false
    },
    handleAdd() {
      this.visible_addrole = true
      this.tabObj = {}
      this.addFlag = true
    },
    handleSubmit(e) {
      e.preventDefault()
      let that = this
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values)
          if (this.addFlag) {
            //新增角色
            saveRole(values)
              .then((res) => {
                //提交成功
                if(res.code==0){
                  this.$message.success('保存成功')
                  that.visible_addrole = false
                  this.$refs.table.refresh()
                }else{
                  this.$message.error(res.message)
                }

              })
              .catch((e) => {
                this.$message.error('保存失败')
              })
          } else {
            //修改角色
            values.id = this.tabObj.id
            updateRole(values)
              .then((res) => {
                //提交成功
                if(res.code==0) {
                  this.$message.success('保存成功')
                  that.visible_addrole = false
                  this.$refs.table.refresh()
                }else{
                  this.$message.error(res.message)
                }
              })
              .catch((e) => {
                this.$message.error('保存失败')
              })
          }
        }
      })
    },
    addRoleCancel() {
      this.visible_addrole = false
    },
    iscandel(record) {
      if (record.type == 1) {
        return false
      } else {
        return true
      }
    },
    bindpermHandleCancel() {
      this.visible_bindperm = false
    },
    handleBind(e) {
      this.visible_bindperm = true
      this.thisRoleId = e.id
      let that = this
      getOrgPermTree().then((res) => {
        this.treeData = res.result
      })
      this.$nextTick(() => {
        //请求回显数据
        getRolePermTree({ roleId: this.thisRoleId }).then((res2) => {
          // that.expandedKeys = res2.result
          that.expandedKeys = []
          if (res2.code == 0 && res2.result.length > 0) {
            let parentids = []
            for (let i in res2.result) {
              if (parentids.indexOf(res2.result[i].parentId) < 0) {
                parentids.push(res2.result[i].parentId)
              }
            }
            for (let i in res2.result) {
              if (parentids.indexOf(res2.result[i].id) < 0) {
                that.expandedKeys.push(res2.result[i].id)
              }
            }
          }
        })
      })
    },
    treeSelect(selectedkys, e) {
      this.submitKeys = []
      for (let i in selectedkys) {
        this.submitKeys.push(selectedkys[i])
      }
      for (let i in e.halfCheckedKeys) {
        this.submitKeys.push(e.halfCheckedKeys[i])
      }
    },
    bindRolePermition() {
      let param = {}
      param.roleId = this.thisRoleId
      param.permitIdList = this.submitKeys
      bindRolePermition(param)
        .then((res) => {
          this.$message.success('保存成功')
        })
        .catch((e) => {
          this.$message.error('保存失败')
        })
      this.visible_bindperm = false
    },
  },
}
</script>
<style lang="less" scoped>
.table-operator {
  margin-bottom: 10px;
}
.custom-tree {
  /deep/ .ant-menu-item-group-title {
    position: relative;
    &:hover {
      .btn {
        display: block;
      }
    }
  }

  /deep/ .ant-menu-item {
    &:hover {
      .btn {
        display: block;
      }
    }
  }

  /deep/ .btn {
    display: none;
    position: absolute;
    top: 0;
    right: 10px;
    width: 20px;
    height: 40px;
    line-height: 40px;
    z-index: 1050;

    &:hover {
      transform: scale(1.2);
      transition: 0.5s all;
    }
  }
}
/deep/ .ant-form-item-control-wrapper{
  width: 80%;
}
/deep/  .form-submit {
    margin-bottom: 0;
  .ant-form-item-control-wrapper{
    width: 100% !important;
    text-align: right;
  }
}
</style>